import "./App.css";
import React, { useEffect, useState } from "react";
import logo from "./Assets/logo.png";
import social1 from "./Assets/social1.svg";
import social2 from "./Assets/social2.svg";
import social3 from "./Assets/social3.svg";
import meta from "./Assets/meta.svg";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./connectors";
import Web3 from "web3";
import { rpcUrl, contractAddress, abi } from "./contracts";

function App() {
  const [open, setOpen] = useState(false);
  const [vault, setVault] = useState("0");
  const [ownBalance, setOwnBalance] = useState("0");
  const [seeds, setSeeds] = useState("0");
  const [bag, setBag] = useState("0");
  const [profit, setProfit] = useState("0");
  const { account, activate, active, library } = useWeb3React();
  const [ref, setRef] = useState();
  const [referalLink, setReferalLink] = useState("");

  useEffect(() => {
    getContractBeans();
    if (account !== undefined) {
      getWalletBeans();
    }
  }, []);

  useEffect(() => {
    if (account !== undefined) {
      getSeedValues();
      setReferalLink(window.location.href + "?ref=" + account);
      getWalletBeans();
    }
  }, [account]);

  async function Connect() {
    try {
      getNetwork();
      setOpen(false);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function getNetwork() {
    if (window.ethereum) {
      try {
        const chainId = await window.ethereum.request({ method: "eth_chainId" });
        if (chainId === "3109") {
          console.log("activating");
          await activate(injected);
        } else {
          await switchNetwork();
          await activate(injected);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function switchNetwork() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "3109" }],
        });
      } catch (error) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: Web3.utils.toHex(3109),
              chainName: "SatoshiVM Alpha Mainnet",
              rpcUrls: ["https://alpha-rpc-node-http.svmscan.io"],
              nativeCurrency: {
                name: "Bitcoin",
                symbol: "BTC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://svmscan.io"],
            },
          ],
        });
      }
    }
  }

  async function getContractBeans() {
    const web3 = new Web3(rpcUrl);
    let balance = await web3.eth.getBalance(contractAddress);
    balance = parseFloat(web3.utils.fromWei(balance)).toFixed(10);
    setVault(balance);
  }

  async function getWalletBeans() {
    const web3 = new Web3(rpcUrl);
    let balance = await web3.eth.getBalance(account);
    balance = parseFloat(web3.utils.fromWei(balance)).toFixed(10);
    setOwnBalance(balance);
  }

  async function checkValid(addy) {
    const web3 = new Web3(rpcUrl);
    console.log(web3.utils.isAddress(addy));
    return web3.utils.isAddress(addy);
  }

  async function plantSeeds() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(abi, contractAddress);

    if (account === undefined) {
      return window.alert("Connect Wallet First");
    }

    console.log(seeds);
    if (seeds === "0") {
      return window.alert("Input how many BTC do you want to spend on Dogtators");
    }

    let referal;

    if (window.location.pathname === "/") {
      referal = account;
    } else if ((await checkValid(window.location.pathname.replace("/", ""))) === false) {
      referal = account;
    } else if ((await checkValid(window.location.pathname.replace("/", ""))) === true) {
      console.log(window.location.pathname.replace("/", ""));
      referal = window.location.pathname.replace("/", "");
    }

    contract.methods
      .buyEggs(referal)
      .send({
        from: account,
        to: contractAddress,
        value: web3.utils.toWei(seeds),
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function compound() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(abi, contractAddress);
    contract.methods
      .hatchEggs()
      .send({
        from: account,
        to: contractAddress,
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function sellEggs() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(abi, contractAddress);

    contract.methods
      .sellEggs()
      .send({
        from: account,
        to: contractAddress,
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function getSeedValues() {
    const web3 = new Web3(rpcUrl);
    const contract = new web3.eth.Contract(abi, contractAddress);

    const bagg = await contract.methods.hatcheryMiners(account).call();
    setBag(bagg);

    const ownedEggs = await contract.methods.getMyEggs().call({ from: account });

    if (ownedEggs === "0") {
      return;
    }
    let calcEggsPrice = await contract.methods.calculateEggSell(ownedEggs).call();

    calcEggsPrice = web3.utils.fromWei(calcEggsPrice);

    setProfit(calcEggsPrice);
  }

  function createReferal() {
    console.log();
  }
  createReferal();

  return (
    <div className="App">
      <div onClick={() => setOpen((prev) => !prev)} className={`overlay ${open ? "display-flex" : ""}`}></div>
      <div className={`pop-up ${open ? "display-flex" : ""}`}>
        <div onClick={Connect} className="pop">
          <img src={meta} alt="" />
          <h2>MetaMask</h2>
        </div>
      </div>
      <div className="top-app">
        <img src={logo} alt="" />
        <p>Obey the Leader</p>
      </div>
      <div className="bottom-app">
        <div className="box">
          <h2>Stats</h2>
          <div className="row">
            <p>Contract Balance</p>
            <p>{vault} BTC</p>
          </div>
          <div className="row">
            <p>Your Wallet</p>
            <p>{ownBalance} BTC</p>
          </div>
          <div className="row">
            <p>Your Dogtators</p>
            <p>{bag} Dogtators</p>
          </div>
          <input type="text" onChange={(e) => setSeeds(e.target.value)} className="input" />
          <div className="btn-div">
            {active ? (
              <button className="connect">Connected</button>
            ) : (
              <button onClick={() => setOpen((prev) => !prev)} className="connect">
                connect Wallet
              </button>
            )}
            <button onClick={plantSeeds} className="yellow-btn">
              Buy Dogtators
            </button>
          </div>

          <div className="btn-div">
            <button onClick={compound} className="compound">
              Compound
            </button>
            <button onClick={sellEggs} className="yellow-btn">
              Claim BTC
            </button>
          </div>
          <div className="row">
            <p>Profit Made</p>
            <p>{profit} BTC</p>
          </div>
        </div>
        <div className="box">
          <h2>Yield</h2>
          <div className="row">
            <p>Daily Profit</p>
            <p>3%</p>
          </div>
          <div className="row">
            <p>APR</p>
            <p>1095%</p>
          </div>
          <div className="row">
            <p>Sustainability Fee</p>
            <p>5%</p>
          </div>
        </div>
        <div className="box">
          <h2>Referral Link</h2>
          <input type="text" className="input" value={referalLink} readOnly />
          <p className="note">
            Receive 3% Straight on top of your Rewards whenever someone is depositing using your Referral Link
          </p>
        </div>
        <div className="box last-box">
          <h2>Strategy</h2>
          <p className="note2">Please note: You can not sell Dogtators they are working for you forever. You can claim/compound the accumulated BTC Rewards anytime.</p>
          <ul>
            <li>Tokenomics:</li>
            <li>Rewards can be re-invested & withdrawn daily.</li>
            <li>To ensure the max rewards (3%) we suggest the following strategy:</li>
            <li>Buy Dogtators (deposit BTC)</li>
            <li>Compound 1x Daily - 6 Times per Week </li>
            <li>Claim BTC - 1x Weekly</li>
            <li>Repeat</li>
            <li>Every deposit/withdrawal carries a 5% Tax for Sustainability</li>
            
          </ul>
        </div>
        </div>
        {<div className="social-div">
          <a href="https://svmscan.io/address/0x605AA50c8Aaa101338E74A370Eb539e07Af5cEA3">
            <img src={social1} alt="" />
          </a>
          <a href="https://t.me/satoshivmminer">
            <img src={social2} alt="" />
          </a>
          <a href="https://twitter.com/satoshivmminer">
            <img src={social3} alt="" />
          </a>
        </div>}
    </div>
  );
}

export default App;
